<template>
    <div>
        <div v-if="isLoading" style="min-height: 300px"
             class="d-flex align-items-center justify-content-center flex-column">
            <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
            <h6 class="text-body mt-3">Loading</h6>

        </div>
        <div v-if="!isLoading && errorLoading" style="min-height: 300px"
             class="d-flex align-items-center justify-content-center flex-column">
            <b-icon-exclamation-triangle class="h3" variant="danger"></b-icon-exclamation-triangle>
            <h6 class="text-danger mt-3">Ooops, there has been an error</h6>

        </div>
        <div v-if="!isLoading && !errorLoading">
            <h5 class="border-bottom pb-2 text-primary">{{ client.id !== undefined ? "Edit" : "Create"}} api client <span
                    v-if="organisationName != null">({{ organisationName }})</span></h5>
            <form v-if="secret == null" autocomplete="off">
                <b-form-group
                        label-size="sm"
                        label="Client name:">
                    <b-form-input size="sm" v-model="client.name" placeholder="Normally the name of the app"/>
                    <error-display v-model="errors" ident="name"></error-display>
                </b-form-group>
                <b-form-group
                        label-size="sm"
                        label="Client id:">
                    <b-form-input size="sm" v-model="client.email" placeholder="unique, lowercase string containing only letters and full stops"/>
                    <error-display v-model="errors" ident="email"></error-display>
                </b-form-group>


                <b-form-group v-if="this.$store.getters.isSuper">
                    <b-form-checkbox
                            v-model="client.is_super"
                            value="1"
                            unchecked-value="0"
                    >
                        <div class="font-weight-bold">
                            Super admin access
                        </div>
                        <div class="text-muted font-weight-light" style="font-size: 14px;">
                            (this client will have access to all organisations)
                        </div>
                    </b-form-checkbox>
                    <error-display v-model="errors" ident="is_super"></error-display>
                </b-form-group>

                <b-form-group v-if="client.is_super !== '1'" label-size="sm"
                              label="Limit to this organisation:">
                    <div v-if="isLoadingOrganisations" class="d-flex align-items-center border p-2 rounded">
                        <b-spinner small variant="primary" class="mr-2"></b-spinner>
                        Loading organisations
                    </div>
                    <div v-else>
                        <v-select
                                ref="organisationSelect"
                                label="name"
                                v-model="client.organisation_id"
                                :multiple="false"
                                :reduce="option => option.value"
                                :options="organisations"
                                :filterable="true"
                                placeholder="Limit to this organisation">
                        </v-select>

                    </div>

                    <error-display v-model="errors" ident="organisation_id"></error-display>
                </b-form-group>


                <div class="text-right">
                    <b-button @click="saveClient" variant="success">Save</b-button>
                </div>
            </form>

            <div  class="text-center" v-if="secret != null">
                <h6 class="font-weight-bold">Here are the new api credentials for {{ client.name }}:</h6>
                <p class="mb-2">Client ID: <strong>{{ client.email }}</strong></p>
                <p class="mb-2">Client Secret:</p>
                <div style="font-size:10px;" class="bg-light p-3 overflow-auto text-center border mb-2 font-weight-bold">
                    {{ secret }}
                </div>
                <div class="alert alert-danger">
                    This secret will only be visible once. Please copy it to somewhere secure as it will not be visible again.
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {getResource, saveResource} from "../../modules/api/methods";
    import ErrorDisplay from "../form_inputs/ErrorDisplay";
    import _ from 'lodash'
    import {
        baseApi,
        clientDetail, clientSave,
        organisationsDropdown,
        userDetail,
        userSave,
        usersDropdown
    } from "../../modules/api/endpoints";
    import vSelect from "vue-select"
    import {containsErrors} from "../../modules/helpers/helpers";

    export default {
        name: "ClientEdit",
        components: {ErrorDisplay, "v-select": vSelect,},
        props: {
            clientId: null,
            organisationId: null,
            organisationName: null,
        },
        data: function () {
            return {
                errorLoading: false,
                isLoading: false,
                isLoadingOrganisations: false,
                client: {},
                errors: {},
                secret : null,
                apiEndpoint: baseApi() + 'organisations/dropdown',
                organisations: [],
            }
        },
        created() {
            if (this.clientId != null) {
                this.loadClient();
            } else {
                this.loadAllOrganisations();
                this.client.app_access = 1;
            }
        },
        methods: {
            loadAllOrganisations() {
                this.isLoadingOrganisations = true;
                saveResource(organisationsDropdown).then((resp) => {
                    this.organisations = resp.data.success;
                }).catch((ex) => {
                    this.$root.$children[0].handleApiError(ex, this.loadAllOrganisations);
                }).finally(() => {
                    this.isLoadingOrganisations = false;
                });
            },
            loadClient() {
                this.isLoading = true;
                getResource(clientDetail(this.clientId)).then((resp) => {
                    var client = resp.data.success.data
                    if (client.roles !== undefined && client.roles.length > 0) {
                        _.each(client.roles, function (obj) {
                            console.log("role", obj);
                            if (obj.super !== undefined && obj.super === 1) {
                                client.is_super = 1;
                            }
                            if (obj.admin !== undefined && obj.admin === 1) {
                                client.is_admin = 1;
                            }
                            if (obj.app_access !== undefined && obj.app_access === 1) {
                                client.app_access = 1;
                            }
                            if (obj.billing !== undefined && obj.billing === 1) {
                                client.billing = 1;
                            }
                        });
                    }
                    this.client = user;
                    this.isLoading = false;
                    this.errorLoading = false;
                    this.loadAllUsers();
                }).catch((ex) => {
                    this.$root.$children[0].handleApiError(ex, this.loadUser);
                    this.isLoading = false;
                    this.errorLoading = true;
                });
            },
            saveClient() {
                this.isLoading = true;
                this.errors = {};
                saveResource(clientSave, this.client).then((resp) => {
                    this.isLoading = false;
                    this.secret = resp.data.success.data.secret;
                }).catch((err) => {
                    console.log(err);
                    if (containsErrors(err)) {
                        this.errors = err.data.errors;
                    }
                    this.isLoading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>
